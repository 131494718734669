import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryTaskGallery = async (options) => {
    try {
        return query(COLLECTIONS.TASK_GALLERY, options);
    } catch (error) {
        throw error;
    }
};
