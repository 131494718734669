import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth-context";
import { Link, useHistory, useLocation } from "react-router-dom";
import { exportExcel } from "../../services/excel";
import { useParams } from "react-router-dom-v5-compat";
import { getTask } from "../../services/firestore/Task";
import { queryTaskDetails } from "../../services/firestore/Task_Details";
import { getProduct } from "../../services/firestore/Product";
import { queryTaskGallery } from "../../services/firestore/Task_Gallery";

export const HEADROW = [
    { id: "id", label: "No.", hidden: true, excelHidden: true, style: { textAlign: "center" } },
    { id: "product_number", label: "Code", style: { textAlign: "left" } },
    { id: "en_name", label: "Product", style: { textAlign: "left" } },
    { id: "warehouse_quantity_cartons", label: "W/H Qty. (Carton)", style: { textAlign: "left" } },
    { id: "warehouse_quantity_pieces", label: "W/H Qty. (Pieces)", style: { textAlign: "left" } },
    { id: "warehouse_quantity_order", label: "Re-Order Qty.", style: { textAlign: "left" } },
];

export const EXCEL_COLUMNS = [
    { key: "product_number", header: "Code", type: "normal", width: "auto" },
    { key: "en_name", header: "Product", type: "normal", width: "auto" },
    { key: "warehouse_quantity_cartons", header: "W/H Qty. (Carton)", type: "normal", width: "auto" },
    { key: "warehouse_quantity_pieces", header: "W/H Qty. (Pieces)", type: "normal", width: "auto" },
    { key: "warehouse_quantity_order", header: "Re-Order Qty.", type: "normal", width: "auto" },
];

export const useTaskInventoryReport = () => {
    const { id: task_id } = useParams();
    const { companyData, userData } = useAuth();
    const [initializing, setInitializing] = useState(false);
    // const [loading, setLoading] = useState(false);

    // const [authorized, setAuthorized] = useState(true);
    // const history = useHistory();
    // const { search: queryString } = useLocation();

    const [rows, setRows] = useState([]);

    const generateExcel = async () => {
        try {
            exportExcel({
                filename: "Inventory Report",
                columns: EXCEL_COLUMNS,
                rows: rows,
                worksheetTitle: "Report",
                user: userData,
                company: companyData,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const TABLE_TOOLBAR_ACTIONS = [
        {
            title: "Excel",
            action: generateExcel,
            icon: "excel",
            enabled: () => true,
        },
    ];

    const init = async () => {
        try {
            setInitializing(true);
            const [taskDetails, taskGallery] = await Promise.all([
                // task_id ? (await getTask(task_id)).data() : null,
                task_id
                    ? (
                          await queryTaskDetails([{ key: "task_id", operator: "==", value: Number(task_id) }])
                      ).map((t) => t.data())
                    : null,
                task_id
                    ? (
                          await queryTaskGallery([{ key: "task_id", operator: "==", value: Number(task_id) }])
                      ).map((t) => t.data())
                    : null,
            ]);

            console.log(taskDetails, taskGallery);

            // get unique product ids
            let productIDs = taskDetails.map((taskDetail) => taskDetail.product_id);
            productIDs = [...new Set(productIDs)];
            // get products info
            let productsInfo = await Promise.all(
                productIDs.map(async (id) => {
                    return (await getProduct(id)).data();
                })
            );

            let rows = [];
            for (const taskDetail of taskDetails) {
                let product = productsInfo.find((p) => p.id === taskDetail.product_id);

                rows.push({
                    id: taskDetail.product_id,
                    product_number: product?.product_number ?? "-",
                    en_name: product?.En_name ?? "-",
                    warehouse_quantity_cartons: taskDetail.warehouse_quantity_cartons ?? "-",
                    warehouse_quantity_pieces: taskDetail.warehouse_quantity_pieces ?? "-",
                    warehouse_quantity_order: taskDetail.warehouse_quantity_order ?? "-",
                });
            }

            setRows(rows);
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    };
    useEffect(() => {
        init();
    }, []);

    return {
        //states
        initializing,
        TABLE_TOOLBAR_ACTIONS,
        rows,
    };
};
