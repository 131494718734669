import React, { useEffect, useState } from "react";
import { HEADROW, useTaskInventoryReport } from "./useTaskInventoryReport";
import styles from "./styles";
import { CircularLoader, Header } from "../../core-ui/custom";
import EnhancedTable from "../../components-lagacy/Table/EnhancedTable";
import { useParams } from "react-router-dom-v5-compat";

const TaskInventoryReport = () => {
    const classes = styles();
    const { TABLE_TOOLBAR_ACTIONS, rows, initializing } = useTaskInventoryReport();
    const { id: task_id } = useParams();

    if (initializing) return <CircularLoader className={classes.circularLoader} />;

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>{`Inventory Report (Visit ${task_id})`}</Header>
            <div className={classes.content}>
                <div className={classes.tableWrapper}>
                    <EnhancedTable
                        title={"Inventory Report"}
                        columns={HEADROW}
                        rows={rows}
                        actions={TABLE_TOOLBAR_ACTIONS}
                        updateSelected={() => {}}
                        selectionDisabled
                        loading={initializing}
                    />
                </div>
            </div>
        </section>
    );
};

export default TaskInventoryReport;
