import { Route, Switch } from "react-router-dom";
import { PublicRoute, PrivateRoute, LagacyRoute } from "./components";
import { AuthContextProvider } from "./contexts/auth-context";
import "./core-ui/core-styles/css/global.module.css";
import "./core-ui/core-styles/scss/material-kit-react.scss";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
    SignIn,
    SignUp,
    UpdateAccountInfo,
    ForgotPassword,
    ResetPassword,
    SelectOutletBranches,
    Dashboard,
    Gallery,
    Users,
    DailyMerchandisingReport,
    ExpiryAndDamageReport,
    NearExpiryReport,
    OutOfStockReport,
    SelectDistributionMethod,
    VerifyEmail,
    Products,
    AddProduct,
    UpdateProduct,
    Brands,
    LinkOutlets,
    LinkedProductsWithOutlets,
    LinkedProductsWithOutletBranches,
    LinkOutletBranches,
    ProductStockSettings,
    ProductMerchandisingProjects,
    TaskInventoryReport,
} from "./pages";
import {
    AccountInfo,
    AddBranch,
    AddExtraDisplayContract,
    AddOutsourceProject,
    // AddProduct,
    BranchesList,
    ExtraDisplayContracts,
    Guide,
    LinkedOutlets,
    LinkOutletWithProducts,
    ManageOutsourceProject,
    OutsourceProjects,
    OutsourceVisitDetails,
    OutsourceVisitReport,
    OutsourceVisitsManagement,
    // ProductsList,
    // SelectOutlets,
    UpdateBranch,
    UpdateExtraDisplayContract,
    // UpdateProduct,
} from "./pages-lagacy";
import PATHS from "./constants/paths";
import { GeolocationContextProvider } from "./contexts/geolocation-context";
import { CompatRoute } from "react-router-dom-v5-compat";

function App() {
    return (
        <GeolocationContextProvider>
            <AuthContextProvider>
                <Switch>
                    <PublicRoute path={PATHS.SIGN_IN} exact>
                        <SignIn />
                    </PublicRoute>
                    <PublicRoute path={PATHS.SIGN_UP} exact>
                        <SignUp />
                    </PublicRoute>
                    <PublicRoute path={PATHS.FORGOT_PASSWORD} exact>
                        <ForgotPassword />
                    </PublicRoute>
                    <PublicRoute path={PATHS.RESET_PASSWORD} exact>
                        <ResetPassword />
                    </PublicRoute>
                    <CompatRoute path={PATHS.VERIFY_EMAIL} exact>
                        <VerifyEmail />
                    </CompatRoute>
                    <PrivateRoute path={PATHS.UPDATE_ACCOUNT_INFO} exact>
                        <UpdateAccountInfo />
                    </PrivateRoute>
                    {/* users management */}
                    <PrivateRoute path={`${PATHS.USERS}`} exact>
                        <Users />
                    </PrivateRoute>

                    {/* Dashboard */}
                    <LagacyRoute path={PATHS.HELP} exact>
                        <Guide />
                    </LagacyRoute>
                    {/* Guide */}
                    <PrivateRoute path={PATHS.DASHBOARD} exact>
                        <Dashboard />
                    </PrivateRoute>
                    {/* Services */}
                    <LagacyRoute path={PATHS.OUTSOURCE_PROJECTS} exact>
                        <OutsourceProjects />
                        {/* <ProductMerchandisingProjects /> */}
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.ADD_OUTSOURCE_PROJECT} exact>
                        <AddOutsourceProject />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.MANAGE_OUTSOURCE_PROJECT} exact>
                        <ManageOutsourceProject />
                    </LagacyRoute>

                    {/* Operations */}

                    <LagacyRoute path={PATHS.OUTSOURCE_VISITS_MANAGEMENT} exact>
                        <OutsourceVisitsManagement />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.OUTSOURCE_VISIT_DETAILS} exact>
                        <OutsourceVisitDetails />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.OUTSOURCE_VISIT_REPORT} exact>
                        <OutsourceVisitReport />
                    </LagacyRoute>
                    <PrivateRoute path={PATHS.GALLERY} exact>
                        <Gallery />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${PATHS.OUTSOURCE_VISIT_INVENTORY_REPORT}/:id`}
                        oPath={PATHS.OUTSOURCE_VISIT_INVENTORY_REPORT}
                        exact
                    >
                        <TaskInventoryReport />
                    </PrivateRoute>

                    {/* Reports */}
                    <PrivateRoute path={PATHS.DAILY_MERCHANDISING_REPORT} exact>
                        <DailyMerchandisingReport />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.EXPIRY_AND_DAMAGE_REPORT} exact>
                        <ExpiryAndDamageReport />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.NEAR_EXPIRY_REPORT} exact>
                        <NearExpiryReport />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.OUT_OF_STOCK_REPORT} exact>
                        <OutOfStockReport />
                    </PrivateRoute>

                    {/* Account */}
                    <LagacyRoute path={PATHS.ACCOUNT_INFO} exact>
                        <AccountInfo />
                    </LagacyRoute>
                    {/* Branches */}
                    <LagacyRoute path={PATHS.BRANCHES} exact>
                        <BranchesList />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.ADD_BRANCH} exact>
                        <AddBranch />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.UPDATE_BRANCH} exact>
                        <UpdateBranch />
                    </LagacyRoute>

                    {/* Products */}
                    <PrivateRoute path={PATHS.BRANDS} exact>
                        <Brands />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.PRODUCTS} exact>
                        <Products />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.ADD_PRODUCT} exact>
                        <AddProduct />
                    </PrivateRoute>
                    <PrivateRoute path={`${PATHS.UPDATE_PRODUCT}/:id`} oPath={PATHS.UPDATE_PRODUCT} exact>
                        <UpdateProduct />
                    </PrivateRoute>
                    {/* <LagacyRoute path={PATHS.PRODUCTS} exact>
                        <ProductsList />
                    </LagacyRoute> */}
                    {/* <LagacyRoute path={PATHS.ADD_PRODUCT} exact>
                        <AddProduct />
                    </LagacyRoute> */}
                    {/* <LagacyRoute path={PATHS.UPDATE_PRODUCT} exact>
                        <UpdateProduct />
                    </LagacyRoute> */}

                    {/* Extra Displays */}
                    <LagacyRoute path={PATHS.EXTRA_DISPLAY_CONTRACTS} exact>
                        <ExtraDisplayContracts />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.ADD_EXTRA_DISPLAY_CONTRACT} exact>
                        <AddExtraDisplayContract />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.UPDATE_EXTRA_DISPLAY_CONTRACT} exact>
                        <UpdateExtraDisplayContract />
                    </LagacyRoute>

                    {/* Outlets */}
                    <PrivateRoute path={PATHS.LINK_OUTLETS} exact>
                        <LinkOutlets />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.LINK_OUTLET_BRANCHES} exact>
                        <LinkOutletBranches />
                    </PrivateRoute>

                    {/* <LagacyRoute path={PATHS.LINK_OUTLETS} exact>
                        <SelectOutlets />
                    </LagacyRoute> */}
                    <PrivateRoute path={PATHS.SELECT_DISTRIBUTION_METHOD} exact>
                        <SelectDistributionMethod />
                    </PrivateRoute>
                    {/* <PrivateRoute path={PATHS.LINK_OUTLET_BRANCHES} exact>
                        <SelectOutletBranches />
                    </PrivateRoute> */}
                    {/* <LagacyRoute path={PATHS.LINKED_OUTLETS} exact>
                        <LinkedOutlets />
                    </LagacyRoute> */}
                    <LagacyRoute path={PATHS.LINK_OUTLET_WITH_PRODUCTS} exact>
                        <LinkOutletWithProducts />
                    </LagacyRoute>
                    <PrivateRoute path={PATHS.LINK_PRODUCTS_WITH_OUTLETS} exact>
                        <LinkedProductsWithOutlets />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.LINK_PRODUCTS_WITH_OUTLET_BRANCHES} exact>
                        <LinkedProductsWithOutletBranches />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.PRODUCT_STOCK_SETTINGS} exact>
                        <ProductStockSettings />
                    </PrivateRoute>

                    <PublicRoute path="/" exact>
                        <SignIn />
                    </PublicRoute>
                    <Route path="*">
                        <span>404: No Page Was Found.</span>
                    </Route>
                </Switch>
            </AuthContextProvider>
        </GeolocationContextProvider>
    );
}

export default App;
