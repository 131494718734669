export const COLLECTIONS = {
    COUNTRY: "Country",
    CITY: "City",
    SUPPLIER: "Supplier",
    OUTLET_CLASS: "Outlet_Class",
    LOG: "Log",
    OUTLET: "Outlet",
    OUTLET_BRANCH: "Outlet_Branch",
    ACTIVITY: "Activity",
    USER: "User",
    SUBSCRIPTION: "Subscription",
    SUBSCRIPTION_SLOT: "Slot",
    PROJECT: "Project",
    PROJECT_SCHEDULES: "Project_Schedules",
    SCHEDULE_PRODUCTS: "Schedule_Products",
    SCHEDULE_DISPLAYS: "Schedule_Displays",
    PROJECT_BRANCH: "Branch_Outlet_Product",
    PROJECT_OUTLET_BRANCH: "Outlet_Product",
    PROJECT_SCHEDULE: "Schedule",
    PROJECT_PRODUCT: "Product",
    MERCHANDISER: "Merchandiser",
    SUPERVISOR: "Supervisor",
    TASK: "Task",
    TASK_PRODUCT: "Task_Product",
    TASK_DETAILS: "Task_Details",
    TASK_GALLERY: "Task_Gallery",
    LINK_OUTLET_REQUEST: "Link_Outlet_Request",
    VIDEO_TUTORIALS: "Video_Tutorials",
    TERMS: "Terms_And_Conditions",
    TERMS_AGREEMENTS: "Terms_And_Conditions_Agreements",
    PRIVACY_AGREEMENTS: "Privacy_Policies_Agreements",
    PRIVACY: "Privacy_Policies",
    BRANDS: "Brands",
    COMPETITOR_BRANDS: "Competitor_Brands",
    CATEGORY: "Category",
    SUBCATEGORY: "SubCategory",
    SUB_SUBCATEGORY: "Sub_SubCategory",
    PRODUCTS: "Product",
    COMPETITOR_PRODUCTS: "Competitor_Products",
    PROMOTION_PRICES: "Promotion_Prices",
    LINKED_OUTLETS: "Linked_Outlets",
    LINKED_OUTLET_BRANCHES: "Linked_Outlet_Branches",

    OUTLET_SUPPLIER_LINKS: "Outlet_Supplier_Links",
    RECEIPT_VOUCHER: "Receipt_Voucher",

    BONUS: "Bonus",
    BONUS_TRACKERS: "Bonuses_Tracker",

    //prices
    DELIVERY_PRICE: "Delivery_Price",
    PRICE: "Price",

    //lagacy
    SUPPLIER_OUTLET_PRODUCT: "Supplier_Outlet_Product",
    OUTLET_PRODUCT: "Outlet_Product",
    BRANCH_OUTLET_BRANCH: "Branch_Outlet_Branch",
};
