import { cloneDeep } from "lodash";
import { useState } from "react";
import Swal from "sweetalert2";
import COLORS from "../constants/colors";
import PATHS from "../constants/paths";
import { useAuth } from "../contexts/auth-context";
import {
    INITIAL_VALUES,
    DISPLAY_UNITS,
    FORM_SCHEMA,
    PACKING_UNITS,
    PERIOD_UNITS,
    PRODUCT_TYPES,
    SIZE_UNITS,
    STATUS,
} from "../pages/update-product/schema";
import { getProduct, setProduct } from "../services/firestore/Product";
import useProduct from "./use-product";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

const useUpdateProduct = () => {
    const { companyData } = useAuth();
    // const history = useHistory();
    const navigate = useNavigate();

    const { id: product_id } = useParams();
    const [authorized, setAuthorized] = useState(true);
    const [loading, setLoading] = useState(false);
    const [initValues, setInitValues] = useState(INITIAL_VALUES);

    const onAfterInit = async (categories, subcategories, subsubcategories, brands) => {
        try {
            setLoading(true);
            const product = (await getProduct(product_id)).data();
            if (!product || product.supplier_id !== companyData.company_id) {
                setAuthorized(false);
                setLoading(false);
                return;
            }
            let category = categories.find((c) => c.value === product.product_category.category_id);
            //select unclassified by default
            if (!category) category = categories.find((c) => c.value === "110");

            let subcategory = subcategories.find((sc) => sc.value === product.product_category.sub_category_id);
            //select first matched sub category by default
            if (!subcategory) subcategory = subcategories.find((sc) => sc.data.category_id === category.value);

            let subsubcategory = subsubcategories.find(
                (ssc) => ssc.value === product.product_category.sub_sub_category_id
            );
            if (!subsubcategories)
                subsubcategory = subsubcategories.find((ssc) => ssc.data.sub_category_id === subcategory.value);

            //select options for autocomplete inputs
            let brand = brands.find((b) => b.value === product.brand_id) ?? null;
            let type = PRODUCT_TYPES.find((b) => b.value === product.type) ?? PRODUCT_TYPES[0];
            let packing =
                PACKING_UNITS.find((pu) => pu.value.toLowerCase() === product.packing.packing.toLowerCase()) ??
                PACKING_UNITS[0];
            let displayUnit =
                DISPLAY_UNITS.find((du) => du.value.toLowerCase() === product.display_unit) ?? DISPLAY_UNITS[0];
            let sizeUnit =
                SIZE_UNITS.find((su) => su.value.toLowerCase() === product.packing.size_unit.toLowerCase()) ??
                SIZE_UNITS[0];
            let expirationPeriodUnit =
                PERIOD_UNITS.find((pu) => pu.value.toLowerCase() === product.expiration_period_unit.toLowerCase()) ??
                PERIOD_UNITS[0];
            let productRecallPeriodUnit =
                PERIOD_UNITS.find(
                    (pu) => pu.value.toLowerCase() === product.product_recall_period_unit.toLowerCase()
                ) ?? PERIOD_UNITS[0];
            let shelfLifeUnit =
                PERIOD_UNITS.find((pu) => pu.value.toLowerCase() === product.shelf_life_unit.toLowerCase()) ??
                PERIOD_UNITS[0];

            //string to number
            product.packing.size = Number(product.packing.size) ?? 1;
            product.weight = Number(product.weight) ?? 1;
            product.expiration_period = Number(product.expiration_period) ?? 1;
            product.product_recall_period = Number(product.product_recall_period) ?? 1;
            product.shelf_life = Number(product.shelf_life) ?? 1;

            setInitValues((prev) => ({
                ...prev,
                ...product,
                brand_id: brand,
                packing: {
                    ...product.packing,
                    packing,
                    size_unit: sizeUnit,
                },
                display_unit: displayUnit,
                expiration_period_unit: expirationPeriodUnit,
                product_recall_period_unit: productRecallPeriodUnit,
                shelf_life_unit: shelfLifeUnit,
                product_category: {
                    category_id: category,
                    sub_category_id: subcategory,
                    sub_sub_category_id: subsubcategory,
                },
                product_image: {
                    product_image1: product.product_image.product_image1 ? product.product_image.product_image1 : null,
                    product_image2: product.product_image.product_image2 ? product.product_image.product_image2 : null,
                    product_image3: product.product_image.product_image3 ? product.product_image.product_image3 : null,
                },
                type,
                manufacture_date: product.manufacture_date?.toDate() ?? new Date(),
            }));

            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const {
        initializing,
        saving,
        setSaving,
        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
        UNCALSSIFIED_CATEGORY,
        UNCALSSIFIED_SUBCATEGORY,
        UNCALSSIFIED_SUB_SUBCATEGORY,
    } = useProduct({ onAfterInit });

    const castValues = (values) => {
        //get values from auto complete
        const newProduct = cloneDeep(FORM_SCHEMA.cast(values));
        newProduct.packing.packing = newProduct.packing.packing.value ?? PACKING_UNITS[0].value;
        newProduct.packing.size_unit = newProduct.packing.size_unit.value ?? SIZE_UNITS[0].value;
        newProduct.display_unit = newProduct.display_unit.value ?? DISPLAY_UNITS[0].value;
        newProduct.status = newProduct.status.value ?? STATUS[0].value;
        newProduct.brand_id = newProduct.brand_id?.value ?? null;
        newProduct.product_category.category_id =
            newProduct.product_category.category_id.value ??
            categoriesList.find((c) => c.value === UNCALSSIFIED_CATEGORY).value;
        newProduct.product_category.sub_category_id =
            newProduct.product_category.sub_category_id.value ??
            subcategoriesList.find((c) => c.value === UNCALSSIFIED_SUBCATEGORY).value;
        newProduct.product_category.sub_sub_category_id =
            newProduct.product_category.sub_sub_category_id.value ??
            subsubcategoriesList.find((c) => c.value === UNCALSSIFIED_SUB_SUBCATEGORY).value;

        //fmcg
        newProduct.expiration_period_unit = newProduct.expiration_period_unit.value ?? PERIOD_UNITS[0].value;
        newProduct.product_recall_period_unit = newProduct.product_recall_period_unit.value ?? PERIOD_UNITS[0].value;
        newProduct.shelf_life_unit = newProduct.shelf_life_unit.value ?? PERIOD_UNITS[0].value;

        //number to string
        newProduct.packing.size = `${newProduct.packing.size ?? 1}`;
        newProduct.weight = `${newProduct.weight ?? 1}`;
        newProduct.expiration_period = `${newProduct.expiration_period ?? 1}`;
        newProduct.product_recall_period = `${newProduct.product_recall_period ?? 1}`;
        newProduct.shelf_life = `${newProduct.shelf_life ?? 1}`;

        return newProduct;
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);

            const newValues = castValues(values);

            const data = { ...newValues };
            const updatedProduct = await setProduct(data);

            const result = await Swal.fire({
                title: `Product has been Updated!`,
                text: `Product ${updatedProduct.En_name} has been successfully updated.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });

            if (!result.value) {
                return false;
            } else {
                // history.push({
                //     pathname: PATHS.PRODUCTS,
                //     state: {
                //         selfService: true,
                //         projectType: project.project_type,
                //         id: project.supplier_id,
                //         // mpId: this.props.id,
                //         projectId: project.project_id,
                //     },
                // });
                setSaving(false);
                // history.push({ pathname: PATHS.PRODUCTS });
                navigate({ pathname: PATHS.PRODUCTS });
            }
        } catch (error) {
            console.log(error);
            setSaving(false);
        }
    };

    return {
        initValues,
        setInitValues,
        handleSubmit,
        initializing,
        saving,
        loading,

        authorized,

        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
    };
};

export default useUpdateProduct;
