import { USER_TYPES } from "./global";

const PATHS = {
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    VERIFY_EMAIL: "/verify-email",

    TERMS_AND_CONDITIONS: "https://derwaza.tech/privacy-policy/",
    PRIVACY_POLICY: "https://derwaza.tech/terms-and-conditions/",

    USERS: "/users",

    DASHBOARD: "/dashboard",
    HELP: "/help",

    ACCOUNT_INFO: "/account-info",
    UPDATE_ACCOUNT_INFO: "/update-account-info",

    BRANCHES: "/branches",
    ADD_BRANCH: "/add-branch",
    UPDATE_BRANCH: "/update-branch",

    BRANDS: "/brands",

    PRODUCTS: "/products",
    ADD_PRODUCT: "/add-product",
    UPDATE_PRODUCT: "/update-product",

    EXTRA_DISPLAY_CONTRACTS: "/extra-display-contract",
    ADD_EXTRA_DISPLAY_CONTRACT: "/add-extra-display-contract",
    UPDATE_EXTRA_DISPLAY_CONTRACT: "/update-extra-display-contract",

    LINK_OUTLETS: "/link-outlets",
    SELECT_DISTRIBUTION_METHOD: "/select-distribution-method",
    LINK_OUTLET_BRANCHES: "/link-outlet-branches",
    LINKED_OUTLETS: "/linked-outlets",
    LINK_OUTLET_WITH_PRODUCTS: "/link-outlet-products",
    LINK_PRODUCTS_WITH_OUTLETS: "/link-products-with-outlets",
    LINK_PRODUCTS_WITH_OUTLET_BRANCHES: "/link-products-with-outlet-branches",
    PRODUCT_STOCK_SETTINGS: "/product-stock-settings",

    //* SERVICES
    OUTSOURCE_PROJECTS: "/outsource-projects",
    ADD_OUTSOURCE_PROJECT: "/add-outsource-project",
    MANAGE_OUTSOURCE_PROJECT: "/manage-outsource-project",

    //* OPERATIONS
    OUTSOURCE_VISITS_MANAGEMENT: "/outsource-visits-management",
    OUTSOURCE_VISIT_DETAILS: "/outsource-visit-details",
    OUTSOURCE_VISIT_REPORT: "/outsource-visit-report",
    OUTSOURCE_VISIT_INVENTORY_REPORT: "/outsource-visit-inventory-report",
    GALLERY: "/gallery",

    //* REPORTS
    DAILY_MERCHANDISING_REPORT: "/daily-merchandising-report",
    EXPIRY_AND_DAMAGE_REPORT: "/expiry-and-damage-report",
    NEAR_EXPIRY_REPORT: "/near-expiry-report",
    OUT_OF_STOCK_REPORT: "/out-of-stock-report",
};

const PUBLIC_PATHS = [
    PATHS.SIGN_IN,
    PATHS.SIGN_UP,
    PATHS.FORGOT_PASSWORD,
    PATHS.RESET_PASSWORD,
    PATHS.TERMS_AND_CONDITIONS,
    PATHS.PRIVACY_POLICY,
];

export const PATHS_ACCESS = {
    [USER_TYPES.ADMIN]: Object.values(PATHS).reduce((prev, path) => ({ ...prev, [path]: true }), {}),
    [USER_TYPES.BRANCH_MANAGER]: Object.values(PATHS).reduce((prev, path) => {
        let permission = true;
        if (path === PATHS.USERS) permission = false;
        return { ...prev, [path]: permission };
    }, {}),
};

export { PUBLIC_PATHS };
export default PATHS;
